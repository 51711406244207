import React, { useRef } from 'react'
import clsx from 'clsx'
import { IconArrowUpRight } from '@hubble/icons'
import Link from 'next/link'
import { motion, useInView } from 'framer-motion'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { type CalendarDrop } from '@/types/homepage'
import SlidingText from './SlidingText'
import { StyledSlideContainer } from './styles'

type DropSlideProps = {
  isActive: boolean
  drop: CalendarDrop
  onClick?: (slideIndex: number) => void
}

function DropSlide({ isActive, drop }: DropSlideProps) {
  const matches1280 = useMediaQueryState('(min-width:1280px)')
  const tagLineClassName = clsx({
    tagline: true,
    offset: !!drop.offset,
  })

  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } }
  const ref = useRef(null)
  const isInView = useInView(ref)

  if (isActive) {
    return (
      <Link
        href={drop.linkUrl}
        passHref
        style={{ textDecoration: 'none', color: '#fff' }}
      >
        <StyledSlideContainer
          className='container'
          ref={ref}
          imgSrc={drop.imageUrl}
          addTransform={!matches1280}
          addTint={drop.addTint}
        >
          <div className='img--overlay' />
          <section className='heading'>
            <div style={{ textAlign: 'left' }}>
              <SlidingText isInView={isInView}>
                <h2>{drop.date}</h2>
              </SlidingText>
              {drop.taglineArray.map((tagline, i) => (
                <SlidingText key={i} isInView={isInView} delay={i * 0.5}>
                  <p className={tagLineClassName}>{tagline}</p>
                </SlidingText>
              ))}
            </div>
            <div style={{ paddingLeft: '24px', textAlign: 'right' }}>
              <SlidingText isInView={isInView}>
                <h2>{drop.dropName}</h2>
              </SlidingText>

              {drop.linkLabel ? (
                <div className='div--cta'>
                  <SlidingText isInView={isInView}>
                    <div>
                      <p className='link--cta'>{drop.linkLabel}</p>
                      <IconArrowUpRight />
                    </div>
                  </SlidingText>
                </div>
              ) : null}
            </div>
          </section>
          <div className='description'>
            <motion.p
              animate={isInView ? 'visible' : 'hidden'}
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible,
              }}
              transition={{ delay: 0.5 }}
            >
              {drop.description}
            </motion.p>
          </div>
        </StyledSlideContainer>
      </Link>
    )
  }
  return null
}

export default DropSlide
