/**
 * Calculates the framer-motion animation timings based on
 * the number of collections being animated.
 * @param collectionCount The number of artist collections to animate
 * @returns An array of timings
 */
export default function getTimings(collectionCount: number) {
  collectionCount = Math.floor(collectionCount)

  /**
   * Determines the ratio of total animation time
   * used for animating the collections in/out.
   * The lower the ratio
   * the later the animation starts
   * and the end earlier it ends.
   */
  const animationLength = 0.9
  const collectionAnimationLength = animationLength / collectionCount
  /**
   * Determines the ratio of time a collection
   * spends animating in/out vs. remaining stationary.
   */
  const transitionRatio = 2 / 3

  const timings = [0]
  for (let i = 0; i < collectionCount; i++) {
    const currentTime = timings[timings.length - 1]
    const transitionTime =
      currentTime + collectionAnimationLength * transitionRatio
    const stationaryTime =
      transitionTime + collectionAnimationLength * (1 - transitionRatio)
    timings.push(transitionTime, stationaryTime)
  }
  timings.push(1)

  return timings
}
