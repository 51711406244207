import { useInView } from 'framer-motion'
import React, { useEffect, useRef } from 'react'

interface VideoPlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  src: string
  className?: string
  style?: React.CSSProperties
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  className,
  style,
  ...rest
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const isInView = useInView(videoRef)

  useEffect(() => {
    if (videoRef.current) {
      if (isInView) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [isInView])

  return (
    <video
      ref={videoRef}
      className={className}
      src={src}
      style={style}
      muted
      autoPlay
      playsInline
      loop
      {...rest}
    >
      Your browser does not support the video tag.
    </video>
  )
}

export default VideoPlayer
