import { MotionValue, useTransform } from 'framer-motion'
import { CaseStudySizes, ImageSizes } from './useCaseStudyImageSize'

/**
 * Hook used to calculate the animation of
 * a div masking a case study image.
 *
 * The mask is animated to mimic
 * an opaque window sliding open from the top.
 */
export default function useMaskHeight(
  scrollProgress: MotionValue<number>,
  timings: number[],
  size: CaseStudySizes
) {
  const { height: imgHeight, gap } = ImageSizes[size];
  // Final height should contain two images plus the gap in between them
  const height = imgHeight * 2 + gap
  const [zero, firstCollectionTransition] = timings
  const maskTimings = [
    zero,
    // start animating when the first collection transition is halfway done
    firstCollectionTransition / 2,
    firstCollectionTransition,
    1,
    1,
  ]
  const maskHeight = useTransform(scrollProgress, maskTimings, [
    0,
    0,
    height,
    height,
    0,
  ])
  return { maskHeight }
}
