import { Theme } from '@mui/material'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useEffect, useState } from 'react'

enum DisplaySize {
  Mobile,
  Medium,
  Large,
  XLarge,
}

const titleFont = (displaySize: DisplaySize) => ({
  fontSize: ['3rem', '7rem', '10rem', '14rem'][displaySize],
  letterSpacing: ['-0.25rem', '-0.65rem', '-0.75rem', '-1rem'][displaySize],
})

const subtitleFont = (displaySize: DisplaySize) => ({
  fontSize: ['1rem', '2rem', '3rem', '3.75rem'][displaySize],
})

const titleAlignment = {
  display: 'inline-block',
  lineHeight: 0.8,
  fontWeight: 550,
}
const subtitleAlignment = {
  ...titleAlignment,
  lineHeight: 1.2,
}

export default function useHeroStyle() {
  let displaySize = DisplaySize.Mobile

  const [styles, setStyles] = useState({
    titleStyle: { ...titleAlignment, ...titleFont(displaySize) },
    subtitleStyle: { ...subtitleAlignment, ...subtitleFont(displaySize) },
  })

  if (useMediaQueryState((theme: Theme) => theme.breakpoints.up('sm')))
    displaySize = DisplaySize.Medium

  if (useMediaQueryState((theme: Theme) => theme.breakpoints.up('md')))
    displaySize = DisplaySize.Large

  if (useMediaQueryState((theme: Theme) => theme.breakpoints.up('lg')))
    displaySize = DisplaySize.XLarge

  useEffect(() => {
    const titleStyle = {
      ...titleAlignment,
      ...titleFont(displaySize),
    }
    const subtitleStyle = {
      ...subtitleAlignment,
      ...subtitleFont(displaySize),
    }
    setStyles({ titleStyle, subtitleStyle })
  }, [displaySize])

  return styles
}
