import { fetchApi } from "@/utils/api";
import { PaginationResponse } from "@/types/api_types";

export type APIRecentWork = {
    artist_name: string;
    collection_title: string;
    image1_url: string;
    image2_url: string;
    image3_url: string;
    image4_url: string;
    link_url: string;
    position?: number;
}

export const getRecentWork = (): Promise<PaginationResponse<APIRecentWork>> => {
    return fetchApi({
        path: '/custom-web-content/recent-work/'
    })
}