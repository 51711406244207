import { useScroll, useTransform } from 'framer-motion'
import React from 'react'

/**
 * Tracks the scrolling progress as the ref reaches the top of the viewport
 * and animates the clock into view when the
 * top of the ref has nearly reached the top of the viewport.
 */
export default function useClockTransform(ref: React.RefObject<HTMLElement>) {
  const { scrollYProgress: scrollEnterYProgress } = useScroll({
    target: ref,
    offset: [`start center`, `start start`],
  })
  const y = useTransform(scrollEnterYProgress, [0, 1], [-300, 0])

  return { y }
}
