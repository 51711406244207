'use client'

import React from 'react'
import { useAnalytics } from '@/providers/AnalyticsContext'

import { NSGFooter } from '@/elements/src'

import LoadingAnimation from './_components/LoadingAnimation'

import MarketingSection from './_sections/marketing'
import { CalendarDrops } from './_components/CalendarDrops'
import { HighlightedCalendarDrop } from './_components/HighlightedCalendarDrop'
import StudioText from './_sections/studio-text'
import WhatWeDo from './_sections/what-we-do'
import CaseStudies from './_sections/case-studies'

import { useAppSelector } from '@/redux/store'

const HomePageClientShell = () => {
  const { hasLoadingAnimationPlayed } = useAppSelector((state) => state.uiInfo)
  const { logPageView, logTimeEvent } = useAnalytics()

  React.useEffect(() => {

    const handleBeforeUnload = () => {
      window.scrollTo(0, 0)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    logTimeEvent?.('Homepage Viewed')

    return () => {
      
      window.removeEventListener('beforeunload', handleBeforeUnload)

      logPageView?.('Homepage Viewed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <LoadingAnimation />
      <div style={{ opacity: hasLoadingAnimationPlayed ? '1' : '0' }}>
        <HighlightedCalendarDrop />
        <StudioText />
        <CalendarDrops />
        <CaseStudies />
        <WhatWeDo />
        <MarketingSection />
        <NSGFooter />
      </div>
    </>
  )
}

export default HomePageClientShell
