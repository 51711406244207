import {
  REFIK_ANADOL_CASE_STUDY_DROP,
  SAM_SPRATT_CASE_STUDY_DROP,
} from '../../../constants'
import { CaseStudyV2 } from '@/types/homepage'

export default function getFallbackCaseStudyData(): CaseStudyV2[] {
  const [caseStudy1, caseStudy2] = [SAM_SPRATT_CASE_STUDY_DROP, REFIK_ANADOL_CASE_STUDY_DROP]
 
  return [
    {
      artist: caseStudy1.artist,
      collection: caseStudy1.title,
      link: caseStudy1.pastWorkLinkUrl ?? caseStudy1.linkUrl,
      image1_url: caseStudy1.images[5], 
      image2_url: caseStudy1.images[4],
      image3_url: caseStudy1.images[1],
      image4_url: caseStudy1.images[2],
    },
    {
      artist: caseStudy2.artist,
      collection: caseStudy2.title,
      link: caseStudy2.pastWorkLinkUrl ?? caseStudy2.linkUrl,
      image1_url: caseStudy2.images[2], 
      image2_url: caseStudy2.images[1],
      image3_url: caseStudy2.images[5],
      image4_url: caseStudy2.images[4],
    }
  ]
}