import { Fragment, useCallback, useEffect } from 'react'
import {
  motion,
  useAnimate,
  stagger,
  useInView,
  useScroll,
  useTransform,
} from 'framer-motion'
import Link from 'next/link'
import Image from 'next/image'
import { useAnalytics } from '@/providers/AnalyticsContext'
import { MIXPANEL_EVENTS, SECTION_LABELS } from '../../../constants'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import useHeaderNavHeight from '@/hooks/useHeaderNavHeight'

/**
 * Renders cascading text and images by animating
 * the `top` CSS prop and uses various sizing techniques
 * to obscure elements until they 'drop' into view.
 */
export default function TrustedByBrandsCascade() {
  const [ref, animate] = useAnimate()
  const inView = useInView(ref, {
    amount: 0.5,
    once: true,
  })

  const isMedium = useMediaQueryState((theme) => theme.breakpoints.up('sm'))
  const isLarge = useMediaQueryState((theme) => theme.breakpoints.up('md'))
  const isXLarge = useMediaQueryState((theme) => theme.breakpoints.up('lg'))
  /**
   * Magic numbers were chosen by inspecting the computed size
   * of `.image-container` at each breakpoint.
   * The `.image-mask` is set to this size to fully cover the `.image`.
   * For convenience, the same initial offset is used for the texts.
   */
  let initialTopOffset = 54
  if (isMedium) initialTopOffset = 78
  if (isLarge) initialTopOffset = 86
  if (isXLarge) initialTopOffset = 102

  useEffect(() => {
    if (inView) {
      animate(
        '[data-animation=cascading-texts]',
        {
          /** Using 5 values breaks the animation into .25 sec transitions */
          top: [-initialTopOffset, -initialTopOffset, 0, 0, 0],
          color: ['#fff', '#fff', '#fff', '#fff', '#555'],
        },
        {
          duration: 1,
          delay: stagger(0.1),
        },
      )

      animate(
        '[data-animation=cascading-images]',
        {
          top: [-initialTopOffset, 0],
        },
        {
          duration: 1,
          delay: stagger(0.2),
        },
      )
    }
  }, [inView, animate, initialTopOffset])

  /** Scale to 0 when scrolling out of view */
  const { realNavHeight } = useHeaderNavHeight()
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: [`start ${realNavHeight}px`, 'end start'],
  })
  const scale = useTransform(scrollYProgress, [0, 1], [1, 0])

  const { logEvent } = useAnalytics()
  const handleLinkClick = useCallback(
    (index: number, title: string, href: string) => () => {
      logEvent?.(MIXPANEL_EVENTS.MARKETING_LINK_CLICK, 'CTAClick', {
        position: index,
        title,
        href,
      })
    },
    [logEvent],
  )

  const images = SECTION_LABELS.MARKETING.ARTIST
  const textItems = SECTION_LABELS.MARKETING.TITLE[0].split(' ')

  return (
    <motion.div className='trusted-by-wrapper' style={{ scale }}>
      <div className='trusted-by' ref={ref}>
        {textItems.map((textItem, idx) => {
          const artistMeta = getArtistMeta(images, idx)
          return (
            <Fragment key={idx}>
              {/**
               * Specify z-indexes on `.text-container` items
               * so that succeeding text drops in from behind,
               * as opposed to in front of, preceding text.
               * Specify `flex-grow: 1` on `.text-container`to fill in horizontal space
               * with background color so that succeeding text
               * is not visible until it lowers.
               */}
              <div
                key={idx}
                className='text-container'
                style={{ zIndex: textItems.length - idx }}
              >
                <span
                  className='text'
                  data-animation='cascading-texts'
                  style={{ top: -initialTopOffset }}
                >
                  {textItem}
                </span>
              </div>
              {artistMeta && (
                <Link
                  key={idx + '_img'}
                  className='image-container'
                  style={{ zIndex: textItems.length - idx }}
                  href={artistMeta.href}
                  onClick={handleLinkClick(
                    idx,
                    artistMeta.title,
                    artistMeta.href,
                  )}
                >
                  {/**
                   * Images remain stationary and are revealed by an `.image-mask`
                   * which drops out of view and is clipped by the parent `.image-container`
                   */}
                  <Image
                    className='image'
                    loading='lazy'
                    src={artistMeta.image}
                    alt='logo'
                    width={80}
                    height={80}
                    title={artistMeta.title}
                  />
                  <div
                    className='image-mask'
                    data-animation='cascading-images'
                    style={{ top: -initialTopOffset }}
                  ></div>
                </Link>
              )}
            </Fragment>
          )
        })}
      </div>
    </motion.div>
  )
}

/** Insert images after each textItem specified by these indexes */
const imagePlacements = [1, 2, 4, 5, 6]
const getArtistMeta = (artists: Record<string, string>[], idx: number) => {
  if (!imagePlacements.includes(idx)) return null
  const imageIdx = imagePlacements.indexOf(idx)
  const artist = artists[imageIdx % artists.length]
  return artist
}
