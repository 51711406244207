import { styled } from '@mui/material'
import * as webTokens from '@hubble/tokens/web'
import { StyledSectionContainer } from '../../styles'

const StyledContainer = styled(StyledSectionContainer)`
  background-color: #000;
  color: ${webTokens.color.base.white};
  height: 100vh;
  padding: 0;
  position: relative;

  .hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  .near-center {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);
  }
`

export default StyledContainer
