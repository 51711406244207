import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector, useAppDispatch } from '@/redux/store'
import { styled } from '@mui/material'
import { motion, useAnimate, AnimatePresence } from 'framer-motion'
import useHeaderNavHeight from '@/hooks/useHeaderNavHeight'
import { setHasLoadingAnimationPlayed } from '@/redux/reducers/ui'

const primaryColor = '#000'
const borderGrowDuration = 0.5
const primaryBorderDelay = 2
const secondaryBorderDelay = 2.5
const borderFillDelay = 3
const borderSize = '20px'
const setTimeoutDelay = 650

const NIFTY_TEXT = 'Nifty'
const GATEWAY_TEXT = 'Gateway'
const STUDIO_TEXT = 'Studio'

const StyledDiv = styled('div')`
  font-size: 8vw;
  letter-spacing: -0.5vw;

  .loadingAnimation__textWrapper {
    gap: 4px;
  }

  .loadingAnimation__gatewayText,
  .loadingAnimation__studioText {
    margin-left: 4px;
  }

  @media (max-width: 550px) {
    .loadingAnimation__textWrapper {
      gap: 2px;
    }

    .loadingAnimation__gatewayText,
    .loadingAnimation__studioText {
      margin-left: 2px;
    }
  }
`

const LoaderAnimation = () => {
  const divContainerRef = useRef<HTMLDivElement>(null)
  const [isSafeToRemove, setIsSafeToRemove] = useState(false)
  const [scope, animate] = useAnimate()
  const { navHeight } = useHeaderNavHeight()
  const { hasLoadingAnimationPlayed } = useAppSelector((state) => state.uiInfo)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (hasLoadingAnimationPlayed) return

    if (divContainerRef.current) divContainerRef.current.style.opacity = '1'

    if (document?.body) {
      document.body.style.overflowY = 'hidden'
      document.body.style.overflowX = 'hidden'
    }

    animate(
      '#__loadingAnimation__textWrapper__',
      { x: [10, -10] },
      { duration: 1, delay: 1, type: 'spring', stiffness: 80, damping: 20 },
    )

    animate(
      '#__loadingAnimation__niftyText__',
      { y: [100, 0], opacity: [0, 1] },
      { duration: 1, type: 'spring', stiffness: 80, damping: 20 },
    )

    animate(
      '#__loadingAnimation__gatewayText__',
      { y: [100, 0], opacity: [0, 1] },
      {
        duration: 0.5,
        delay: 0.35,
        type: 'spring',
        stiffness: 80,
        damping: 20,
      },
    )

    animate(
      '#__loadingAnimation__studioText__',
      { x: [-100, 4], opacity: [0, 1] },
      { duration: 1, delay: 1, type: 'spring', stiffness: 80, damping: 20 },
    )

    animate(
      '#__loadingAnimation__leftBorder__',
      { height: [0, '100%'] },
      { duration: borderGrowDuration, delay: primaryBorderDelay },
    )

    animate(
      '#__loadingAnimation__topBorder__',
      { width: [0, '100%'] },
      { duration: borderGrowDuration, delay: primaryBorderDelay },
    )

    animate(
      '#__loadingAnimation__botBorder__',
      { width: [0, '100%'] },
      { duration: borderGrowDuration, delay: secondaryBorderDelay },
    )

    animate(
      '#__loadingAnimation__rightBorder__',
      { height: [0, '100%'] },
      {
        duration: borderGrowDuration,
        delay: secondaryBorderDelay,
        onComplete: () => {
          setTimeout(() => {
            document.body.style.overflowY = 'auto'
            setIsSafeToRemove(true)
            dispatch(setHasLoadingAnimationPlayed(true))
          }, setTimeoutDelay)
        },
      },
    )

    animate(
      '#__loadingAnimation__leftBorder__',
      { width: [borderSize, '100%'] },
      { duration: 1, delay: borderFillDelay, ease: 'easeIn' },
    )
    animate(
      '#__loadingAnimation__topBorder__',
      { height: [borderSize, '100%'] },
      { duration: 1, delay: borderFillDelay, ease: 'easeIn' },
    )
    animate(
      '#__loadingAnimation__botBorder__',
      { height: [borderSize, '100%'] },
      { duration: 1, delay: borderFillDelay, ease: 'easeIn' },
    )
    animate(
      '#__loadingAnimation__rightBorder__',
      { width: [borderSize, '100%'] },
      {
        duration: 1,
        delay: borderFillDelay,
        ease: 'easeIn',
      },
    )

    return () => {
      if (document?.body) {
        document.body.style.overflowY = 'auto'
      }

      dispatch(setHasLoadingAnimationPlayed(true))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AnimatePresence mode='wait'>
      {!isSafeToRemove && !hasLoadingAnimationPlayed && (
        <motion.div
          ref={scope}
          key='loadingAnimationWrapper'
          initial={{ opacity: 1 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
            zIndex: 100,
          }}
        >
          <StyledDiv
            ref={divContainerRef}
            sx={{
              opacity: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              color: primaryColor,
              fontFamily: 'inherit',
              fontWeight: 500,
            }}
          >
            <div
              id='__loadingAnimation__textWrapper__'
              className='loadingAnimation__textWrapper'
              style={{ display: 'flex' }}
            >
              {/* Nifty text */}
              <div id='__loadingAnimation__niftyText__'>{NIFTY_TEXT}</div>
              {/* Gateway text */}
              <div
                id='__loadingAnimation__gatewayText__'
                className='loadingAnimation__gatewayText'
              >
                {GATEWAY_TEXT}
              </div>
              {/* Studio text */}
              <div
                id='__loadingAnimation__studioText__'
                className='loadingAnimation__studioText'
              >
                {STUDIO_TEXT}
              </div>
            </div>
          </StyledDiv>

          <div
            style={{
              position: 'absolute',
              top: `${navHeight}px`,
              bottom: 0,
              right: 0,
              left: 0,
              width: '100vw',
              boxSizing: 'border-box',
            }}
          >
            {/* Left border */}
            <div
              id='__loadingAnimation__leftBorder__'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: borderSize,
                backgroundColor: primaryColor,
              }}
            />

            {/* Top border */}
            <div
              id='__loadingAnimation__topBorder__'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: borderSize,
                backgroundColor: primaryColor,
              }}
            />

            {/* Bottom border */}
            <div
              id='__loadingAnimation__botBorder__'
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: borderSize,
                backgroundColor: primaryColor,
              }}
            />

            {/* Right border */}
            <div
              id='__loadingAnimation__rightBorder__'
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: borderSize,
                backgroundColor: primaryColor,
              }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default LoaderAnimation
