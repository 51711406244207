import { APIRecentWork, getRecentWork } from "@/api/recentWork"
import { useQuery } from '@tanstack/react-query'
import { RECENT_WORK_QUERY_KEY } from "../../../constants"
import { CaseStudyV2 } from "@/types/homepage"
import getFallbackCaseStudyData from '../utils/getFallbackCaseStudyData'

export const translateAPIData = (data: APIRecentWork): CaseStudyV2 => {
    return {
      artist: data.artist_name,
      collection: data.collection_title,
      image1_url: data.image1_url,
      image2_url: data.image2_url,
      image3_url: data.image3_url,
      image4_url: data.image4_url,
      link: data.link_url,
    }
  }

  export const useRecentWork = () => {
    const { data, ...rest } = useQuery({
      queryKey: [RECENT_WORK_QUERY_KEY],
      queryFn: getRecentWork,
      select: (response): CaseStudyV2[] => {
        return response.results.map((record) => translateAPIData(record))
      }
    })
    return {
      recentWork: data ?? getFallbackCaseStudyData(),
      ...rest
    }
  }