import React from 'react'
import { useIntersectionObserver } from '@/elements/src'
import { useAnalytics } from '@/providers/AnalyticsContext'

import { MIXPANEL_EVENTS } from '../../constants'

import StyledContainer from './styles'
import TrustedByBrandsMarquee from './_components/TrustedByBrandsMarquee'
import TrustedByBrandsCascade from './_components/TrustedByBrandsCascade'

const MarketingSection = () => {
  const { logEvent } = useAnalytics()
  const [elementRef] = useIntersectionObserver({
    fireOnce: true,
    cb: () =>
      logEvent?.(MIXPANEL_EVENTS.MARKETING_PROMO_SCROLLED_TO, 'Location'),
    opts: {
      threshold: 0,
    },
  })

  return (
    <>
      <StyledContainer ref={elementRef as React.RefObject<HTMLDivElement>}>
        <TrustedByBrandsCascade />
      </StyledContainer>
      <TrustedByBrandsMarquee />
    </>
  )
}

export default MarketingSection
