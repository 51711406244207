import * as React from 'react'
import { mediaQuery } from '@hubble/web'
import { Box, styled } from '@mui/material'
import { useAnalytics } from '@/providers/AnalyticsContext'
import {
  MIXPANEL_EVENTS,
  SECTION_LABELS,
} from '@/app/(ng-core)/(ng-homepage)/constants'
import * as webTokens from '@hubble/tokens/web'
import Marquee from '@/elements/src/Marquee'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useMemo, useState, useRef } from 'react'

export default function TrustedByBrandsMarquee() {
  const { logEvent } = useAnalytics()
  const isMobile =
    useMediaQueryState((theme) => theme.breakpoints.down('md')) ??
    true /* mobile first! */

  const handleLinkClick = React.useCallback(
    (position: number, segment: typeof SECTION_LABELS.MARKETING.FEATURED[0]) =>
      () => {
        const { href, title } = segment
        logEvent?.(MIXPANEL_EVENTS.MARKETING_LINK_CLICK, 'CTAClick', {
          position,
          title,
          href,
        })
      },
    [logEvent],
  )
  const [mr, contentHeight] = isMobile ? [40, 20] : [125, 60]

  const { current: imageSet } = useRef(new Map<string, HTMLElement>())
  const [imageCount, setImageCount] = useState(0)
  const handleImageLoaded = (img: HTMLImageElement) => {
    imageSet.set(img.src, img)
    setImageCount(imageSet.size)
  }
  const totalContentWidth = useMemo(
    () =>
      imageCount < SECTION_LABELS.MARKETING.FEATURED.length
        ? 0 // don't start animation until all images loaded, to avoid hitches in the animation
        : Array.from(imageSet).reduce(
            /**
             * Each raw image is resized when
             * its height set to `contentheight` in the <img/> element,
             * so when calculating the width we must scale it to match.
             */
            (total, [, img]) => {
              const { width, height } = img.getBoundingClientRect()
              return total + width * (contentHeight / height)
            },
            0,
          ),
    [contentHeight, imageCount],
  )
  const totalMargin = imageSet.size * mr

  return (
    <MarqueeWrapper isMobile={isMobile}>
      {/* Magic number 5 chosen based on image sizes 
        to ensure marquee fills displays up to ~10_000px wide */}
      <Marquee
        direction='toLeft'
        duplicates={5}
        speed={isMobile ? 75 : 200}
        contentWidth={totalContentWidth + totalMargin}
      >
        {SECTION_LABELS.MARKETING.FEATURED.map((segment, index) => (
          <Box
            component='span'
            sx={{ mr: `${mr}px` }}
            key={index}
            onClick={handleLinkClick(index, segment)}
          >
            <a
              href={segment.href}
              target='_blank'
              rel='noreferrer'
              data-testid={`featuredLink-${index}`}
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                loading='eager'
                onLoad={({ currentTarget: img }) => handleImageLoaded(img)}
                src={segment.image}
                alt={segment.title}
                height={contentHeight}
              />
            </a>
          </Box>
        ))}
      </Marquee>
    </MarqueeWrapper>
  )
}

const MarqueeWrapper = styled('div')(
  ({ isMobile }: { isMobile: boolean }) => `
  padding-top: ${isMobile ? '0px' : '50px'};
  padding-bottom: ${isMobile ? '0px' : '50px'};
  background-color: ${webTokens.color.base.black};
  overflow: hidden;
  @media ${mediaQuery.tabletSmDown} {
    margin: 0 auto;
  }
`,
)
