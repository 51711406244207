/* eslint-disable @next/next/no-img-element */
import { useContext } from 'react'
import { useTransform } from 'framer-motion'
import { ScrollContext } from '../_context/ScrollContext'

export default function useArtistAnimationBySequence(
  sequence: number,
  collectionCount: number,
) {
  sequence = Math.floor(sequence)
  const { scrollYProgress, timings } = useContext(ScrollContext)

  const yRange: number[] = []
  const opacityRange: number[] = []
  const artistTimings: number[] = []
  const [startEnter, endEnter, startExit, endExit] = timings.slice(
    2 * sequence,
    2 * sequence + 4,
  )
  const halfWayIn = startEnter + (endEnter - startEnter) / 2
  const halfWayOut = startExit + (endExit - startExit) / 2
  const first = sequence === 0
  const startY = first ? 0 : -50
  const startOpacity = first ? 1 : 0
  const last = sequence === collectionCount - 1
  const endY = last ? 0 : 50
  const endOpacity = last ? 1 : 0
  artistTimings.push(0, halfWayIn, endEnter, startExit, halfWayOut)
  yRange.push(startY, startY, 0, 0, endY)
  opacityRange.push(startOpacity, startOpacity, 1, 1, endOpacity)

  const opacity = useTransform(scrollYProgress, artistTimings, opacityRange)
  const y = useTransform(scrollYProgress, artistTimings, yRange)
  return { opacity, y }
}
