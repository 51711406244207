/* eslint-disable camelcase -- Snake_case props come from the API so we can't change them here */
import { useRecentWork } from './useRecentWork'

interface CaseStudyImagesState {
  artistsMeta: [string, string][]
  collectionNames: string[]
  leftColumnImageData: [string, string][]
  rightColumnImageData: [string, string][]
}

/**
 * Utility hook for collecting and transforming the artist and image data
 * into a shape expected by the components
 * @returns
 */
export default function useCaseStudyImages(): CaseStudyImagesState {
  const caseStudyData = useRecentWork().recentWork

  /** Succinct declaration of output vars and their types */
  const {
    artistsMeta = [],
    collectionNames = [],
    leftColumnImageData = [],
    rightColumnImageData = [],
  } = {} as CaseStudyImagesState

  for (const {
    artist,
    link,
    collection,
    image1_url,
    image2_url,
    image3_url,
    image4_url,
  } of caseStudyData) {
    artistsMeta.push([artist, link])
    collectionNames.push(collection)
    // add filler images to start/bottom of left column
    if (leftColumnImageData.length === 0)
      leftColumnImageData.unshift(
        [image1_url, `${artist}_img_filler_1`],
        [image2_url, `${artist}_img_filler_2`],
      )
    leftColumnImageData.unshift(
      [image1_url, `${artist}_img_1`],
      [image2_url, `${artist}_img_2`],
    )
    // add filler images to start/top of right column
    if (rightColumnImageData.length === 0)
      rightColumnImageData.push(
        [image3_url, `${artist}_img_filler_1`],
        [image4_url, `${artist}_img_filler_2`],
      )
    rightColumnImageData.push(
      [image3_url, `${artist}_img_3`],
      [image4_url, `${artist}_img_4`],
    )
  }

  return {
    artistsMeta,
    collectionNames,
    leftColumnImageData,
    rightColumnImageData,
  }
}
