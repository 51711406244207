import React from 'react'
import { useCalendarInfo } from '../_hooks/useCalendarInfo'
import UpcomingDrops from '../_sections/upcoming-drops'

export const HighlightedCalendarDrop = () => {
  const { highlightedInfo } = useCalendarInfo()
  if (!highlightedInfo) return null

  return <UpcomingDrops drops={[highlightedInfo]} isHighlight />
}
