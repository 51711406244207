import React from 'react'
import { IconArrowUpRight } from '@hubble/icons'
import Link from 'next/link'
import { useAnalytics } from '@/providers/AnalyticsContext'
import { useUser } from '@/providers/UserContext'
import { HIGHLIGHT_SLIDE_EXPLORE_TEXT } from '../../constants'
import { StyledHighlightContainer } from './styles'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { DropData } from '../../types'

interface HighlightSlideProps {
  drop: DropData
}

const HighlightSlide = ({ drop }: HighlightSlideProps) => {
  const { user } = useUser()
  const { logEvent } = useAnalytics()
  const isDesktop = useMediaQueryState('(min-width:1280px)')

  return (
    <Link
      href={drop.linkUrl}
      passHref
      onClick={() => {
        logEvent?.('Homepage Upcoming Section CTA Click', 'CTAClick', {
          title: drop.dropName,
          subtitle: drop.taglineArray,
          destinationURL: drop.linkUrl,
          userId: user?.id || '',
        })
      }}
      style={{ textDecoration: 'none', color: '#fff' }}
    >
      <StyledHighlightContainer
        imgSrc={drop.imageUrl}
        addTransform={!isDesktop}
        addTint={true}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='heading--container'>
          <h2 className='heading--container__title'>{drop.dropName}</h2>
          <section className='heading--container__subtitleArray'>
            {drop.taglineArray.map((text) => {
              return (
                <h2 key={text} className='heading--container__subtitle'>
                  {text}
                </h2>
              )
            })}
          </section>
          <span className='observe--anchor'>
            {drop.linkLabel ? drop.linkLabel : HIGHLIGHT_SLIDE_EXPLORE_TEXT}
            <IconArrowUpRight className='icon' />
          </span>
        </div>
      </StyledHighlightContainer>
    </Link>
  )
}

export default HighlightSlide
