/* eslint-disable @next/next/no-img-element */
import { useMemo, useRef } from 'react'
import dynamic from 'next/dynamic'
import { motion, useScroll } from 'framer-motion'
import {
  CASE_STUDIES_INTRO_TEXT,
  CASE_STUDIES_NIFTY_STUDIO,
  CASE_STUDIES_PAST_WORK,
  DOWN_ARROW_IMG,
} from '../../constants'
import StyledContainer from './styles'
import SwitchIndicator from './_components/SwitchIndicator'
import ArtistName from './_components/ArtistName'
import DropCollectionTitle from './_components/DropCollectionTitle'
import ImageColumn from './_components/ImageColumn'
import { ScrollContext } from './_context/ScrollContext'
import useCaseStudyImages from './_hooks/useCaseStudyImages'
import useMaskHeight from './_hooks/useMaskHeight'
import useClockTransform from './_hooks/useClockTransform'
import ScaleTextOnEnter from './_components/ScaleTextOnEnter'
import getTimings from './utils/getCaseStudyAnimationTimings'
import useCaseStudyImageSize from './_hooks/useCaseStudyImageSize'
import { CaseStudySizeContext } from './_context/CaseStudySizeContext'

const Clock = dynamic(() => import('./_components/Clock'), {
  ssr: false,
})

const CaseStudies = () => {
  const sectionRef = useRef(null)

  /**
   * Elements in the div with class `case-study-fixed-frame` animate as this section
   * scrolls the page. A Context containing a MotionValue state variable
   * is provided to those elements.
   */
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start start', 'end start'],
  })

  const {
    artistsMeta,
    collectionNames,
    leftColumnImageData,
    rightColumnImageData,
  } = useCaseStudyImages()

  const timings = useMemo(
    () => getTimings(artistsMeta.length),
    [artistsMeta.length],
  )
  const size = useCaseStudyImageSize()
  /**
   * The mask contains the case study image section.
   * The image columns are intentionally taller than the mask.
   * The mask uses `contain: paint` to behave like a "window" into
   * the image columns underneath. The mask height animates so
   * that the "window" slides open from the top to reveal the images.
   */
  const { maskHeight } = useMaskHeight(scrollYProgress, timings, size)
  /**
   * The clock should slide into view from the top
   * when the section is almost completely in view.
   */
  const { y: clockY } = useClockTransform(sectionRef)

  const scrollHeight = 100 * (1 + artistsMeta.length) + 50
  const scrollContextValue = useMemo(
    () => ({
      scrollYProgress,
      timings,
    }),
    [scrollYProgress, timings],
  )

  return (
    <ScrollContext.Provider value={scrollContextValue}>
      <CaseStudySizeContext.Provider value={size}>
        <StyledContainer ref={sectionRef}>
          <motion.div
            className='case-study-sticky-clock-right'
            style={{ y: clockY }}
          >
            <Clock />
          </motion.div>
          <div
            className='case-study-scroll-container'
            style={{ height: `${scrollHeight}vh` }}
          >
            <div className='heading'>
              <ScaleTextOnEnter>
                <h2 className='studio--header'>{CASE_STUDIES_NIFTY_STUDIO}</h2>
              </ScaleTextOnEnter>
              {CASE_STUDIES_INTRO_TEXT.map((text) => (
                <ScaleTextOnEnter className='intro--header' key={text}>
                  {/* replace spaces with non-breaking-spaces so each text is one line */}
                  {text.replace(/ /g, '\u00A0')}
                </ScaleTextOnEnter>
              ))}
            </div>
            <img
              className='arrow'
              src={DOWN_ARROW_IMG}
              alt='down arrow'
              title='down arrow'
            />
            <div className='case-study-sticky-labels-left'>
              <h2>{CASE_STUDIES_PAST_WORK}</h2>
              <SwitchIndicator collectionCount={artistsMeta.length} />
            </div>
            <div className='case-study-fixed-frame'>
              <motion.div
                className='case-study-frame-mask'
                style={{
                  height: maskHeight,
                }}
              >
                {artistsMeta.map(([name, link], idx) => (
                  <ArtistName
                    key={name + '_' + idx}
                    href={link}
                    name={name}
                    sequence={idx}
                    collectionCount={artistsMeta.length}
                  />
                ))}
                <div className='case-study-image-column-container'>
                  {collectionNames.map((collection, idx) => (
                    <DropCollectionTitle
                      key={collection + '_' + idx}
                      title={collection}
                      sequence={idx}
                      collectionCount={collectionNames.length}
                    />
                  ))}
                  <ImageColumn
                    position='left'
                    images={leftColumnImageData.map(([src, alt]) => ({
                      src,
                      alt,
                    }))}
                  />
                  <ImageColumn
                    position='right'
                    images={rightColumnImageData.map(([src, alt]) => ({
                      src,
                      alt,
                    }))}
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </StyledContainer>
      </CaseStudySizeContext.Provider>
    </ScrollContext.Provider>
  )
}

export default CaseStudies
