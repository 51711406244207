import { useCallback, useRef, useState } from 'react'
import { useAnimate, ValueAnimationTransition } from 'framer-motion'

export default function useTextRotation({
  duration,
  textItems,
  isTopElement,
  direction,
}: UseTextRotationParam) {
  /**
   * The index of the textItem to return.
   * We use a ref, instead of state,
   * to track the index of the text items being displayed
   * because state doesn't update on each animation frame
   * and can be stale when accessed during onUpdate().
   */
  const textItemIndexRef = useRef(
    isTopElement ? 0 : Math.min(1, textItems.length - 1),
  )

  /**
   * Since updating the textItemIndexRef won't trigger a render,
   * we use a state variable to do so.
   */
  const [textItemIndex, setTextItemIndex] = useState(textItemIndexRef.current)

  /**
   * The ref to return and be applied to the element to be animated,
   * and a function which configures the animation.
   */
  const [textElementref, animate] = useAnimate()

  /**
   * Track when the textItem has rotated out of view
   * so that its displayed text can be updated to
   * the next text in the sequence.
   */
  const rotatedOut = useRef(false)

  /**
   * A function returned to the hook's consumer to (re)start the animation.
   */
  const startAnimation = useCallback(() => {
    if (!textElementref.current) return function stopAnimation() {}

    /**
     * Animate the y position so the element appears to rotate out of the view.
     * Assumes the element's container is set to the height of the element.
     * Note, y is relative to the element's original position,
     * so the lower element has to move up to be in view.
     */
    const { height } = textElementref.current.getBoundingClientRect()
    const aboveView = isTopElement ? -height : -height * 2
    const inView = isTopElement ? 0 : -height
    const belowView = isTopElement ? height : 0
    const down = direction === 'down'

    /**
     * These ratios are multiplied by the duration
     * to determine how long each transition takes.
     * Each text is displayed for 0.45 * duration
     * and takes 0.05 * duration to rotate in/out.
     */
    const { times, opacity, yPositions } = (() => {
      if (isTopElement)
        return {
          times: [0, 0.45, 0.5, 0.95, 0.95, 1],
          opacity: [1, 1, 1, 0, 1, 1],
          yPositions: [
            inView,
            inView,
            down ? belowView : aboveView,
            down ? belowView : aboveView,
            down ? aboveView : belowView,
            inView,
          ],
        }
      return {
        times: [0, 0.05, 0.5, 0.55, 0.55, 1],
        opacity: [1, 1, 1, 1, 0, 0],
        yPositions: [
          down ? aboveView : belowView,
          inView,
          inView,
          down ? belowView : aboveView,
          down ? belowView : aboveView,
          down ? belowView : aboveView,
        ],
      }
    })()

    // Use final the position as an indicator for the end of the animation
    const finalYPosition = down ? belowView : aboveView

    // As animation is (re)starting, reset the text that is displayed
    textItemIndexRef.current = isTopElement
      ? 0
      : Math.min(1, textItems.length - 1)

    const animationTransition: ValueAnimationTransition = {
      times,
      duration,
      /**
       * Stagger the initial animation for the lower element
       * to take start once the top element begins to transition out
       */
      delay: isTopElement ? 0 : duration * 0.45,
      ease: 'linear',
      repeat: Infinity,
    }

    const opacityAnimation = animate(
      textElementref.current,
      {
        opacity,
      },
      animationTransition,
    )

    const heightAndTextAnimation = animate(
      textElementref.current,
      {
        y: yPositions,
      },
      {
        ...animationTransition,
        onUpdate(currentY: number) {
          // Reset the rotatedOut flag if not in final position
          if (currentY !== finalYPosition && rotatedOut.current) {
            rotatedOut.current = false
          }
          // If the textItem has rotated out in this frame, update the textItem index
          if (currentY === finalYPosition && rotatedOut.current === false) {
            rotatedOut.current = true
            /**
             *  Skip the next index as the twin element is already displaying that value.
             *  Wrap around to the start of the list if needed.
             */
            textItemIndexRef.current =
              (textItemIndexRef.current + 2) % textItems.length
            setTextItemIndex(textItemIndexRef.current)
          }
        },
      },
    )
    return function stopAnimation() {
      opacityAnimation.stop()
      heightAndTextAnimation.stop()
    }
  }, [duration, textItems, isTopElement, direction, textElementref, animate])

  return {
    text: textItems[textItemIndex],
    /**
     * The function used by the hook's consumer to (re)start the animation.
     * @returns A function used to stop the animation
     */
    startAnimation,
    ref: textElementref,
  }
}

export interface UseTextRotationParam {
  /** The seconds it takes to complete a cycle (animate in, pause, animate out, pause) */
  duration: number
  /** The array of text items to iterate through on each rotation */
  textItems: string[]
  /** Determines behavior specific to the first/second animation */
  isTopElement: boolean
  /** Determines whether the text rotates to the top or bottom of the viewport */
  direction: 'up' | 'down'
}
