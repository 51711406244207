import React, { useRef } from 'react'
import { IconArrowUpRight } from '@hubble/icons'
import Link from 'next/link'
import { motion, useInView } from 'framer-motion'
import { type CalendarDrop } from '@/types/homepage'
import { StyledSlideContainer } from './styles'

function MobileDropSlide({ drop }: { drop: CalendarDrop }) {
  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } }
  const ref = useRef(null)
  const isInView = useInView(ref)

  return (
    <StyledSlideContainer
      imgSrc={drop.imageUrl}
      ref={ref}
      addTransform
      addTint={drop.addTint}
    >
      <div className='img--overlay' />
      <section className='heading'>
        <motion.div
          style={{ textAlign: 'left' }}
          animate={isInView ? 'visible' : 'hidden'}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible,
          }}
        >
          <h2>{drop.date}</h2>
          <h2>{drop.dropName}</h2>
          {drop.taglineArray.map((tagline, i) => (
            <p key={i}>{tagline}</p>
          ))}
        </motion.div>
      </section>
      <motion.div
        className='description isMobile'
        animate={isInView ? 'visible' : 'hidden'}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible,
        }}
      >
        <div className='descr--wrapper'>
          <p>{drop.description}</p>
        </div>
        {drop.linkUrl ? (
          <div className='div--cta'>
            <Link className='link--cta' href={drop.linkUrl}>
              {drop.linkLabel}
            </Link>
            <IconArrowUpRight />
          </div>
        ) : null}
      </motion.div>
    </StyledSlideContainer>
  )
}

export default MobileDropSlide
