import { useIntersectionObserver } from '@/elements/src'
import { useAnalytics } from '@/providers/AnalyticsContext'
import { Box } from '@mui/material'
import useHeaderNavHeight from '@/hooks/useHeaderNavHeight'

import {
  ANALYTICS_EVENT_NGS_HOME_INTRO_VIEWED,
  NIFTY_GATEWAY_STUDIO,
} from '../../constants'

import HeroSection from '../../_components/HeroSection'
import StyledContainer from './styles'

const StudioText = () => {
  const { realNavHeight } = useHeaderNavHeight()
  const { logEvent } = useAnalytics()
  const [elementRef] = useIntersectionObserver({
    fireOnce: true,
    cb: () => logEvent?.(ANALYTICS_EVENT_NGS_HOME_INTRO_VIEWED, 'Location'),
    opts: {
      threshold: 0,
    },
  })

  return (
    <StyledContainer ref={elementRef as React.RefObject<HTMLDivElement>}>
      <h1 className='hidden'>{NIFTY_GATEWAY_STUDIO}</h1>
      <Box className='near-center' sx={{ marginTop: `-${realNavHeight}px`}}>
        <HeroSection />
      </Box>
    </StyledContainer>
  )
}

export default StudioText
