import React, { type ReactNode } from 'react'
import { motion } from 'framer-motion'

type SlidingTextProps = {
  children: ReactNode
  delay?: number
  duration?: number
  isInView?: boolean
}

const SlidingText: React.FC<SlidingTextProps> = ({
  children,
  delay = 0,
  duration = 0.5,
  isInView = false,
}) => {
  const visible = { opacity: 1, y: 0, transition: { duration: 0.75 } }
  const hidden = { y: '100%' }
  return (
    <div style={{ overflow: 'hidden', paddingBottom: '6px' }}>
      <motion.div
        initial={{ y: '100%' }}
        animate={isInView ? 'visible' : 'hidden'}
        variants={{
          hidden,
          visible,
        }}
        transition={{
          delay,
          duration,
          ease: 'easeOut',
        }}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default SlidingText
