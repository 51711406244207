import { APICalendarInfo, getCalendarInfo } from '@/api/calendar'
import { useQuery } from '@tanstack/react-query'
import { DropData } from '../types'
import { CALENDAR_INFO_QUERY_KEY } from '../constants'

export const translateAPIData = (data: APICalendarInfo):DropData => {
  return {
    artist: data.drop_name,
    date: data.date.value,
    description: data.description,
    dropName: data.drop_name,
    imageUrl: data.image_url,
    index: data.id,
    linkLabel: data.link_label,
    linkUrl: data.link_url,
    taglineArray: data.tag_lines,
    position: data.position,
    addTint: data.add_tint,
    offset: data.offset,
  }
}

export const useCalendarInfo = () => {
  const { data, ...rest } = useQuery({
    queryKey: [CALENDAR_INFO_QUERY_KEY],
    queryFn: () => getCalendarInfo(),
    select: (res) => {
      const [maybeHighlightedInfo, ...sortedList] = res.sort((a, b) => {
        if (a.highlight) return -1
        if (b.highlight) return 1
        return (a.position ?? Infinity) - (b.position ?? Infinity)
      })
      if (maybeHighlightedInfo.highlight)
        return {
          highlightedInfo: translateAPIData(maybeHighlightedInfo),
          sortedList: sortedList.map(translateAPIData),
        }
      return {
        sortedList: [maybeHighlightedInfo, ...sortedList].map(translateAPIData),
      }
    },
  })
  return {
    calendarInfo: data?.sortedList,
    highlightedInfo: data?.highlightedInfo,
    ...rest,
  }
}
