import React, { useEffect, useRef } from 'react'
import { useEffectOnce, useIntersection } from 'react-use'
import { Box } from '@mui/material'
import { useAnimate } from 'framer-motion'
import RotatableText from './RotatableText'
import useHeroStyle from '../_hooks/useHeroStyle'
import useTwinTextRotation from '../_hooks/useTwinTextRotation'
import {
  STUDIO_HERO_SUBTITLES,
  STUDIO_HERO_TITLE_TOP,
  STUDIO_HERO_TITLES_BOTTOM,
} from '../constants'

// The animation cycle time in seconds (two rotations)
const duration = 4

export default function HeroSection() {
  /**
   * These hooks perform the animation.
   * We simply start/stop them in this component
   * when they scroll into/out of view.
   */
  const {
    startAnimation: startTitleAnimation,
    texts: titleTexts,
    refs: titleRefs,
  } = useTwinTextRotation({
    duration,
    textItems: STUDIO_HERO_TITLES_BOTTOM,
    direction: 'down',
  })

  const {
    startAnimation: startSubtitleAnimation,
    texts: subtitleTexts,
    refs: subtitleRefs,
  } = useTwinTextRotation({
    duration,
    textItems: STUDIO_HERO_SUBTITLES,
    direction: 'up',
  })

  const heroSectionRef = useRef<HTMLDivElement>(null)
  const intersection = useIntersection(heroSectionRef, {})
  const stopAnimation = useRef(() => {})
  /**
   * We want the animation to recalculate and restart
   * when the display/font size changes.
   */
  const { titleStyle, subtitleStyle } = useHeroStyle()

  useEffect(() => {
    if (intersection && intersection.intersectionRatio > 0) {
      const stopTitleAnimation = startTitleAnimation()
      const stopSubtitleAnimation = startSubtitleAnimation()
      stopAnimation.current = () => {
        stopTitleAnimation()
        stopSubtitleAnimation()
      }
    } else {
      stopAnimation.current()
    }
  }, [
    intersection,
    startTitleAnimation,
    startSubtitleAnimation,
    titleStyle,
    subtitleStyle,
  ])

  /** Short animations for the initial load. */
  const [topTitleRef, topTitleIntroAnimation] = useAnimate()
  const [bottomTitleRef, bottomTitleIntroAnimation] = useAnimate()
  const [subtitleRef, subtitleIntroAnimation] = useAnimate()
  useEffectOnce(() => {
    topTitleIntroAnimation(
      topTitleRef.current,
      {
        y: ['-' + titleStyle.fontSize, 0],
      },
      { duration: 0.2 },
    )
    bottomTitleIntroAnimation(
      bottomTitleRef.current,
      {
        y: [titleStyle.fontSize, 0],
      },
      { duration: 0.2 },
    )
    subtitleIntroAnimation(
      subtitleRef.current,
      {
        y: [subtitleStyle.fontSize, 0],
        opacity: [0, 1],
      },
      { duration: 0.2, delay: 0.2 },
    )
  })

  return (
    <Box ref={heroSectionRef} sx={{ textAlign: 'center' }}>
      <div ref={topTitleRef} style={titleStyle}>
        {STUDIO_HERO_TITLE_TOP}
      </div>
      <div ref={bottomTitleRef}>
        <RotatableText style={titleStyle} texts={titleTexts} refs={titleRefs} />
      </div>
      <div ref={subtitleRef}>
        <RotatableText
          style={subtitleStyle}
          texts={subtitleTexts}
          refs={subtitleRefs}
        />
      </div>
    </Box>
  )
}
