import { motion, useAnimation } from 'framer-motion'
import type { AnimationControls } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import { styled } from '@mui/material'
import { useInView } from 'framer-motion'
/**
 * Animates a repeating marquee of the content to produce an infinite horizontally scrolling element.
 * @param props.children The content to display in the Marquee.
 * @param props.direction Determines the horizontal scroll direction.
 * @param props.speed Determines how quickly the marquee moves in pixel/sec.
 * @param props.duplicates The number of times to replicate the children to fill the display. Should be a positive integer.
 * @param props.contentWidth The discrete width of the children,
 * if provided, will be used as opposed to calculating the width of the element.
 * @returns A horizontally scrolling Marquee
 */
const Marquee: React.FC<React.PropsWithChildren<Partial<MarqueeProps>>> = ({
  direction = 'toRight',
  speed = 200,
  duplicates = 2,
  children: content,
  contentWidth: width,
}) => {
  const animation = useAnimation()
  const unitElementRef = useRef<HTMLDivElement | null>(null)

  const containerRef = useRef<HTMLDivElement>(null)
  const isContainerInView = useInView(containerRef)

  useEffect(() => {
    if (unitElementRef.current && isContainerInView) {
      loopScrollAnimation(animation, unitElementRef.current, {
        speed,
        direction,
        width,
      })
    } else if (!isContainerInView) {
      animation.stop()
    }
  }, [width, direction, speed, animation, isContainerInView])

  return (
    <Container ref={containerRef}>
      <motion.div style={{ position: 'absolute' }} animate={animation}>
        {Array.from({ length: duplicates }, (_, idx) => (
          <span key={idx} ref={idx === 0 ? unitElementRef : null}>
            {content}
          </span>
        ))}
      </motion.div>
    </Container>
  )
}

type ScrollDirection = 'toRight' | 'toLeft'
interface MarqueeProps {
  direction: ScrollDirection
  speed: number
  duplicates: number
  contentWidth?: number
}

/**
 * Configures the animation to scroll a distance equal to the length of one marquee item
 * then reset and repeat.
 * @param marqueeAnimation The animation that controls the marquee
 * @param unitElement The DOM element representing an item in the marquee
 */
const loopScrollAnimation = (
  marqueeAnimation: AnimationControls,
  unitElement: HTMLDivElement,
  opts: {
    speed: number
    direction: ScrollDirection
    width?: number
  },
) => {
  const { speed, direction, width } = opts
  const { width: unitWidth } = width
    ? { width }
    : unitElement.getBoundingClientRect()
  const [startPos, endPos] =
    direction === 'toRight' ? [-unitWidth, 0] : [0, -unitWidth]
  const duration = unitWidth / speed
  marqueeAnimation.set({ x: startPos })
  marqueeAnimation.start({
    x: endPos,
    transition: {
      duration: duration,
      repeat: Infinity,
      ease: 'linear',
    },
  })
}

const Container = styled('div')`
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  width: 100vw;
  height: 4em;
`

export default Marquee
