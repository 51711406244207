import { styled } from '@mui/material'
import * as webTokens from '@hubble/tokens/web'
import { mediaQuery } from '@hubble/web'

export const StyledSectionContainerWithPadding = styled('div')`
  padding-right: ${webTokens.grid.main.xs.margin};
  padding-left: ${webTokens.grid.main.xs.margin};

  .section--wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: ${webTokens.grid.main.xl.minWidth};
    padding: 60px 0 0;
  }

  .grid--container {
    gap: ${webTokens.grid.main.xs.gutter};
  }

  @media (${mediaQuery.tabletSmUp}) {
    padding-right: ${webTokens.grid.main.sm.margin};
    padding-left: ${webTokens.grid.main.sm.margin};

    .grid--container {
      gap: ${webTokens.grid.main.sm.gutter};
    }
  }

  @media (min-width: 960px) {
    padding-right: 60px;
    padding-left: 60px;

    .grid--container {
      gap: ${webTokens.grid.main.sm.gutter};
    }
  }

  @media (min-width: 1280px) {
    padding-right: 120px;
    padding-left: 120px;

    .section--wrapper {
      padding: 80px 0 0;
    }

    .grid--container {
      gap: ${webTokens.grid.main.lg.gutter};
    }
  }
`

export const StyledContainerWithPadding = styled(
  StyledSectionContainerWithPadding,
)`
  background-color: ${webTokens.color.base.white};
  color: ${webTokens.color.base.black};

  .heading--top {
    padding: 0;
    padding-bottom: ${webTokens.spacing[40]};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid ${webTokens.color.base.black};

    .button--refresh {
      margin-left: ${webTokens.spacing[12]};
      white-space: nowrap;
    }
  }

  .button--likes {
    background-color: ${webTokens.color.base.white} !important;
    color: ${webTokens.color.base.black} !important;

    &.number {
      padding-left: 12px;
      padding-right: 12px;

      span:first-child {
        margin-left: 4px;
      }
    }
  }

  .countdown {
    padding-top: 0;
    font-style: normal;
    color: ${webTokens.color.base.black};
    font-weight: ${webTokens.text.base.weight.regular};
    font-size: ${webTokens.text.base.scale[12].fontSize};
    line-height: ${webTokens.text.base.scale[12].marketing.lineHeight};
    letter-spacing: ${webTokens.text.base.scale[12].marketing.letterSpacing};
  }

  .cover--content .badge--status {
    top: 0;
    left: 0;
  }

  .grid--container {
    width: 100%;
    margin: ${webTokens.spacing[32]} auto 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    .nftcard {
      min-width: 0;
    }
  }

  @media (max-width: 920px) {
    .grid--container {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: 800px) {
    .heading--top {
      padding-bottom: ${webTokens.spacing[20]};
    }

    .grid--container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 550px) {
    .grid--container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 350px) {
    .grid--container {
      grid-template-columns: 1fr;
    }
  }
`

export const StyledSectionContainer = styled('div')`
  .section--wrapper {
    margin: 0 auto;
    width: 100%;
  }

  .grid--container {
    gap: ${webTokens.grid.main.xs.gutter};
  }

  @media (${mediaQuery.tabletSmUp}) {
    .grid--container {
      gap: ${webTokens.grid.main.sm.gutter};
    }
  }

  @media (min-width: 960px) {
    .grid--container {
      gap: ${webTokens.grid.main.sm.gutter};
    }
  }

  @media (min-width: 1280px) {
    .grid--container {
      gap: ${webTokens.grid.main.lg.gutter};
    }
  }
`

