import { fetchApi } from '@/utils/api'

export type APICalendarInfo = {
  id: number
  date: {
    value: string
    type: 'datetime' | 'text'
  }
  tag_lines: string[]
  drop_name: string
  image_url: string
  link_url: string
  link_label: string
  description: string
  highlight: boolean
  position?: number
  add_tint?: boolean
  offset?: boolean
}

export const getCalendarInfo = (): Promise<APICalendarInfo[]> => {
  return fetchApi({
    path: '/custom-web-content/calendar-info/',
  })
}
