import { styled } from '@mui/material'
import * as webTokens from '@hubble/tokens/web'
import { CaseStudyTextStyles, ImageSizes } from './_hooks/useCaseStudyImageSize'
import { useContext } from 'react'
import { CaseStudySizeContext } from './_context/CaseStudySizeContext'

const StyledContainer = styled('div')(() => {
  const size = useContext(CaseStudySizeContext)
  const imageSize = ImageSizes[size]
  const { intro, h2, collection, artist } = CaseStudyTextStyles[size]

  return `
  background-color: ${webTokens.color.base.white};
  color: ${webTokens.color.base.black};

  padding: 48px 20px;
  @media (min-width: 600px) {
    padding: 96px 40px;
  }

  overflow: clip;

  .wrapper {
    margin: 0 auto;
  }

  .heading {
    position: relative;
    text-align: left;
    overflow: hidden;
  }

  .studio--header {
    position: relative;
    left: 2px;
    margin-bottom: 12px;
    @media (min-width: 600px) {
      left: 5px;
    }
  }

  .intro--header {
    max-width: 780px;
    margin: 0;
    padding: 0;
    font-weight: ${webTokens.text.base.weight.semiBold};
  }

  h2 {
    max-width: 780px;
    margin: 0;
    padding: 0;
    font-weight: ${webTokens.text.base.weight.semiBold};
  }

  .arrow {
    margin: 30px 0;

    @media (min-width: 600px) {
      margin: 150px 0;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .case-study-scroll-container {
    position: relative;
  }

  .case-study-sticky-labels-left {
    position: sticky;
    top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 960px) {
      top: 100px;
      gap: 150px;
    }
  }

  .case-study-sticky-clock-right {
    position: sticky;
    top: 30px;

    @media (min-width: 960px) {
      top: 100px;
    }
  }

  .case-study-fixed-frame {
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .case-study-frame-mask {
    contain: paint;
    display: flex;
    justify-content: center;
  }

  .case-study-image-column-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  .intro--header {
    font-size: ${intro.fontSize};
    line-height: ${intro.lineHeight};
    letter-spacing: ${intro.letterSpacing};
  }

  h2 {
    font-size: ${h2.fontSize};
    line-height: ${h2.lineHeight};
    letter-spacing: ${h2.letterSpacing};
  }

  .case-study-fixed-frame {
    top: calc(
    50vh - 0.5 *
      (${imageSize.boxHeight}px + ${imageSize.height}px)
  );
    width: 100vw;
  }

  .case-study-image-column-container {
    gap: calc(1 / 6 * ${imageSize.width}px);
  }

  .case-study--image {
    width: ${imageSize.width}px;
    height: ${imageSize.height}px;
  }

  .caseStudyItem--artist-wrapper {
    top: ${imageSize.height}px;
  }

  .caseStudyItem--collection-name {
    width: ${imageSize.width}px;

    font-size: ${collection.fontSize};
    line-height: ${collection.lineHeight};
    letter-spacing: ${collection.letterSpacing};
    top: calc(${imageSize.height}px * (2/3));
    padding-left: 5px;
    padding-right: 5px
    @media (min-width: 600px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    left: calc(50% - 13 / 12 * ${imageSize.width}px);
    position: absolute;
    z-index: 3;
    text-align: left;
    font-weight: 600;
    color: white;
  }

  .caseStudyItem--artist {
    text-transform: uppercase;
    font-weight: ${webTokens.text.base.weight.semiBold};
    font-size: ${artist.fontSize};
    line-height: ${artist.lineHeight};
    letter-spacing: ${artist.letterSpacing};
    padding: 3px 0;
  }

  // #endregion
`
})

export default StyledContainer
