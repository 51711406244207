import React from 'react'
import { useCalendarInfo } from '../_hooks/useCalendarInfo'
import UpcomingDrops from '../_sections/upcoming-drops'

export const CalendarDrops = () => {
  const { calendarInfo } = useCalendarInfo()
  return (
    <>
      {calendarInfo?.map((item) => {
        return <UpcomingDrops key={item.index} drops={[item]} />
      })}
    </>
  )
}
