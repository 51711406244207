import useHeaderNavHeight from '@/hooks/useHeaderNavHeight'
import * as webTokens from '@hubble/tokens/web'
import useMediaQueryState from '@/hooks/useMediaQueryState'

export type CaseStudySizes = keyof typeof ImageSizes

export default function useCaseStudyImageSize(): CaseStudySizes {
  const elbowRoom = 120 // padding-x + collection switch indicator width
  const smallWidth = ImageSizes.sm.width * (13 / 12) * 2 + elbowRoom
  const mediumWidth = ImageSizes.md.width * (13 / 12) * 2 + elbowRoom
  const largeWidth = ImageSizes.lg.width * (13 / 12) * 2 + elbowRoom

  const { realNavHeight } = useHeaderNavHeight()
  const headRoom = realNavHeight + 64  // assumed footer height
  const smallHeight =
    realNavHeight + ImageSizes.sm.height + ImageSizes.sm.boxHeight + headRoom // room for h2 & clock
  const mediumHeight =
    realNavHeight + ImageSizes.md.height + ImageSizes.md.boxHeight + headRoom + 150  // room for h2 & clock
  const largeHeight =
    realNavHeight + ImageSizes.lg.height + ImageSizes.lg.boxHeight + headRoom + 200  // room for h2 & clock 

  const isSmallWidth = useMediaQueryState(`(min-width:${smallWidth}px)`)
  const isMediumWidth = useMediaQueryState(`(min-width:${mediumWidth}px)`)
  const isLargeWidth = useMediaQueryState(`(min-width:${largeWidth}px)`)

  const isSmallHeight = useMediaQueryState(`(min-height:${smallHeight}px)`)
  const isMediumHeight = useMediaQueryState(`(min-height:${mediumHeight}px)`)
  const isLargeHeight = useMediaQueryState(`(min-height:${largeHeight}px)`)

  if (isLargeHeight && isLargeWidth) return 'lg'
  if (isMediumHeight && isMediumWidth) return 'md'
  if (isSmallHeight && isSmallWidth) return 'sm'
  return 'mobile'
}

const aspectRatio = 900 / 710 // H/W
class ImageDimensions {
  width: number
  constructor(width: number) {
    this.width = width
  }
  get height() {
    return this.width * aspectRatio
  }
  get gap() {
    return (this.width * 1) / 3
  }
  get boxHeight() {
    return this.height + this.gap
  }
}
export const ImageSizes = {
  mobile: new ImageDimensions(150),
  sm: new ImageDimensions(200),
  md: new ImageDimensions(275),
  lg: new ImageDimensions(375),
}

export const CaseStudyTextStyles = {
  get lg() {
    return {
      intro: {
        fontSize: '160px',
        lineHeight: 0.8,
        letterSpacing: getStyleForSize('96').letterSpacing,
      },
      clock: getStyleForSize('28'),
      h2: getStyleForSize('72'),
      collection: getStyleForSize('48'),
      artist: getStyleForSize('96'),
    }
  },
  get md() {
    return {
      intro: getStyleForSize('96'),
      clock: getStyleForSize('24'),
      h2: getStyleForSize('48'),
      collection: getStyleForSize('36'),
      artist: getStyleForSize('80'),
    }
  },
  get sm() {
    return {
      intro: getStyleForSize('72'),
      clock: getStyleForSize('24'),
      h2: getStyleForSize('40'),
      collection: getStyleForSize('24'),
      artist: getStyleForSize('56'),
    }
  },
  get mobile() {
    return {
      intro: getStyleForSize('56'),
      clock: getStyleForSize('16'),
      h2: getStyleForSize('24'),
      collection: getStyleForSize('16'),
      artist: getStyleForSize('40'),
    }
  },
}

interface FontStyles {
  fontSize: string
  lineHeight: string
  letterSpacing: string
}
type Scales = '16' | '24' | '28' | '32' | '36' | '40' | '48' | '56' | '72' | '80' | '96'
const { scale } = webTokens.text.base

function getStyleForSize(size: Scales): FontStyles {
  const {
    fontSize,
    lineHeight,
    marketing: { letterSpacing },
  } = scale[size]
  return { fontSize, lineHeight, letterSpacing }
}
