/* eslint-disable @next/next/no-img-element */
import React, { useContext } from 'react'
import { styled } from '@mui/material'

import { motion, useTransform } from 'framer-motion'
import { ScrollContext } from '../_context/ScrollContext'

const StyledMotionDiv = styled(motion.div)`
  height: 40px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  .selector {
    height: 20px;
    width: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .selector-foreground {
    background-color: rgba(0, 0, 0, 1);
  }

  @media (max-width: 600px) {
    flex-direction: row;
  }
`

interface SwitchIndicatorProps {
  collectionCount: number
}

export default function SwitchIndicator({
  collectionCount,
}: SwitchIndicatorProps) {
  const { scrollYProgress, timings } = useContext(ScrollContext)
  const [initial, endEnterCase1] = timings
  const opacity = useTransform(
    scrollYProgress,
    [initial, endEnterCase1],
    [0, 1],
  )

  const switchSelectors = Array.from({ length: collectionCount }, () => null)
  return (
    <StyledMotionDiv style={{ opacity }}>
      {switchSelectors.map((_, idx) => (
        <SwitchSelector key={idx} sequence={idx} />
      ))}
    </StyledMotionDiv>
  )
}

interface SwitchSelectorProps {
  sequence: number
}

function SwitchSelector({ sequence }: SwitchSelectorProps) {
  const { scrollYProgress, timings } = useContext(ScrollContext)
  const timingsStart = sequence * 2
  const timingsEnd = timingsStart + 4
  const [startEnter, endEnter, startExit, endExit] = timings.slice(
    timingsStart,
    timingsEnd,
  )
  const scale = useTransform(
    scrollYProgress,
    [startEnter, endEnter, startExit, endExit],
    [0, 1, 1, 0],
  )
  return (
    <div className='selector'>
      <motion.div
        className='selector selector-foreground'
        style={{
          scale,
        }}
      />
    </div>
  )
}
