import { motion, useTransform } from 'framer-motion'
import React, { useContext } from 'react'
import { ScrollContext } from '../_context/ScrollContext'
import { ImageSizes } from '../_hooks/useCaseStudyImageSize'
import { CaseStudySizeContext } from '../_context/CaseStudySizeContext'

interface ImageColumnProps {
  position: 'left' | 'right'
  images: { src: string; alt: string }[]
}

export default function ImageColumn({
  children,
  position,
  images,
}: React.PropsWithChildren<ImageColumnProps>) {
  const { y, gap } = useImageColumnAnimation(
    Math.floor((images.length - 2) / 2),
    position,
  )

  return (
    <motion.div
      className='case-study--wrapper'
      style={{
        y,
        display: 'flex',
        flexDirection: 'column',
        gap,
      }}
    >
      {children}
      {images.map(({ src, alt }) => (
        // eslint-disable-next-line @next/next/no-img-element
        <img key={alt} className='case-study--image' alt={alt} src={src} />
      ))}
    </motion.div>
  )
}

function useImageColumnAnimation(
  collectionCount: number,
  position: ImageColumnProps['position'],
) {
  const { scrollYProgress, timings } = useContext(ScrollContext)

  const size = useContext(CaseStudySizeContext)
  const { gap, boxHeight } = ImageSizes[size]
  const initialHeight =
    position === 'left' ? -collectionCount * 2 * boxHeight : 0
  const yPos = [initialHeight] // for intro delay

  for (let i = 0; i < collectionCount; i++) {
    const currentPos = yPos[yPos.length - 1]
    const newPos =
      currentPos + (position === 'left' ? 2 * boxHeight : -2 * boxHeight)
    yPos.push(
      newPos, // swap to new position
      newPos, // stay at new position for a bit
    )
  }
  yPos.push(yPos[yPos.length - 1])
  const y = useTransform(scrollYProgress, timings, yPos)
  return { y, gap }
}
