import { styled } from '@mui/material'
import * as webTokens from '@hubble/tokens/web'
import { StyledSectionContainer } from '../../styles'

interface ContainerProps {
  imgSrc: string
  addTransform?: boolean
  addTint?: boolean
}

export const StyledSlideContainer = styled('div', {
  shouldForwardProp: (prop: string) => !['imgSrc', 'addTransform', 'addTint'].includes(prop),
})<ContainerProps>(
  ({ imgSrc, addTransform, addTint }) => `
    width: 100%;
    height: 100vh;
    background: url(${imgSrc}) no-repeat center center fixed;
    background-size: cover;
    transform: ${addTransform ? 'translateZ(0)' : 'none'};
    padding: 60px;
    position: relative;

    @media (max-width: 750px) {
      padding: 20px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${addTint ? 'rgba(0, 0, 0, 0.5)' : ''};
    }
  `,
)

export const StyledHighlightContainer = styled(StyledSlideContainer)`
  padding: 0;

  .heading--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 0 30px;
    position: relative;
    top: -88px;

    h2 {
      text-align: center;
    }

    &__title {
      margin: 0;
      padding: 0;
      font-size: 170px;
      font-weight: 600;
      line-height: 131px;
      letter-spacing: -9.25px;
      text-transform: uppercase;
    }

    &__subtitle {
      margin: 0;
      padding: 0;
      margin-top: 22px;
      font-weight: 500;
      font-size: 40px;
      line-height: 48.41px;
      letter-spacing: -1px;
    }

    &__subtitleArray {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        white-space: nowrap;
        text-align: left;
        margin: 0;

        &:first-child {
          margin-top: 22px;
        }

        &:last-child {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .observe--anchor {
    border: 1px solid #ffffff;
    outline: none;
    width: 239px;
    height: 71px;
    background-color: transparent;
    font-size: 24px;
    font-weight: 400;
    line-height: 22.8px;
    letter-spacing: -1px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 22px;

    .icon {
      margin-left: 2px;
      margin-top: 2px;
    }
  }

  @media (max-width: 1080px) {
    .heading--container {
      &__title {
        font-size: 120px;
        line-height: 100px;
        letter-spacing: -5px;
      }

      &__subtitle {
        font-size: 28px;
        line-height: 30px;
        letter-spacing: -0.25px;
      }
    }

    .observe--anchor {
      font-size: 18px;
      width: 175px;
      height: 52px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: 650px) {
    .heading--container {
      &__title {
        font-size: 60px;
        line-height: 54px;
        letter-spacing: -2.18px;
      }

      &__subtitle {
        font-size: 21px;
        line-height: 26.26px;
        letter-spacing: -0.24px;
      }
    }

    .observe--anchor {
      font-size: 16px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
`

const StyledContainer = styled(StyledSectionContainer)`
  background-color: #000;
  color: ${webTokens.color.base.white};
  position: relative;

  .wrapper {
    margin: 0 auto;
  }

  .section--wrapper {
    margin-top: 60px;
    padding-bottom: 60px;
  }

  .img--background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .heading {
    position: relative;
    display: flex;
    justify-content: space-between;

    h2 {
      padding: 0;
      margin: 0;
      font-weight: ${webTokens.text.base.weight.semiBold};
      font-size: ${webTokens.text.base.scale[72].fontSize};
      line-height: ${webTokens.text.base.scale[72].marketing.lineHeight};
      letter-spacing: ${webTokens.text.base.scale[72].marketing.letterSpacing};
    }

    .tagline {
      max-width: 350px;
      
      &.offset {
        padding-left: 4px;
      }
    }

    p {
      font-weight: ${webTokens.text.base.weight.regular};
      font-size: ${webTokens.text.base.scale[24].fontSize};
      line-height: ${webTokens.text.base.scale[24].marketing.lineHeight};
      letter-spacing: ${webTokens.text.base.scale[24].marketing.letterSpacing};
    }

    a {
      font-weight: ${webTokens.text.base.weight.regular};
      font-size: ${webTokens.text.base.scale[24].fontSize};
      line-height: ${webTokens.text.base.scale[24].marketing.lineHeight};
      letter-spacing: ${webTokens.text.base.scale[24].marketing.letterSpacing};
    }
  }

  .description {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: auto;
    padding: 0;
    transform: translate(-50%, -50%);
      
    &.isMobile {
      width: 100%;
      transform: none;
      top: auto;
      bottom: 10px;
      left: 0;
      padding: 20px 40px;
    }

    p {
      font-weight: ${webTokens.text.base.weight.regular};
      font-size: ${webTokens.text.base.scale[24].fontSize};
      line-height: ${webTokens.text.base.scale[24].marketing.lineHeight};
      letter-spacing: ${webTokens.text.base.scale[24].marketing.letterSpacing};
    }
  }



  .div--cta {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .link--cta {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: ${webTokens.grid.main.sm.margin};
    color: ${webTokens.color.base.white};
    font-weight: ${webTokens.text.base.weight.regular};
    font-size: ${webTokens.text.base.scale[18].fontSize};
    line-height: ${webTokens.text.base.scale[18].marketing.lineHeight};
    letter-spacing: ${webTokens.text.base.scale[18].marketing.letterSpacing};
    text-decoration: none;
  }

  @media (max-width: 750px) {
    .heading {
      h2 {
        font-size: ${webTokens.text.base.scale[40].fontSize};
        line-height: ${webTokens.text.base.scale[40].marketing.lineHeight};
        letter-spacing: ${webTokens.text.base.scale[40].marketing.letterSpacing};
      }

      .tagline {
        max-width: 325px;
      }

      p {
        font-size: ${webTokens.text.base.scale[18].fontSize};
        line-height: ${webTokens.text.base.scale[18].marketing.lineHeight};
        letter-spacing: ${webTokens.text.base.scale[18].marketing.letterSpacing};
      }
    }

    .description {
      align-items: flex-end;

      p {
        font-size: ${webTokens.text.base.scale[16].fontSize};
        line-height: ${webTokens.text.base.scale[20].marketing.lineHeight};
        letter-spacing: ${webTokens.text.base.scale[16].marketing.letterSpacing};
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 734px) and (max-height: 765px),
    only screen and (min-width: 735px) and (max-width: 1068px) and (max-height: 663px),
    only screen and (min-width: 1069px) and (max-width: 1440px) and (max-height: 709px),
    only screen and (min-width: 1441px) and (max-height: 770px) {
    height: auto !important;
    
    .heading {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1280px) {
    .section--wrapper {
      margin-top: 80px;
      padding-bottom: 80px;
    }
  }

  @media (max-width: 800px) {
    .div--cta {
      margin-top: 24px;
    }
  }

  @media (max-width: 800px) {
    h2 {
      font-size: ${webTokens.text.base.scale[36].fontSize};
      line-height: ${webTokens.text.base.scale[36].marketing.lineHeight};
      letter-spacing: ${webTokens.text.base.scale[36].marketing.letterSpacing};
    }

`

export default StyledContainer
