import React, { useRef } from 'react'
import { Container, Grid } from '@mui/material'
import { IconArrowUpRight } from '@hubble/icons'
import { Link } from '@/elements/src'
import { motion, useInView } from 'framer-motion'
import {
  ABOUT_BG_VIDEO,
  WHAT_WE_DO_ANCHOR_TEXT,
  WHAT_WE_DO_DESCRIPTION,
  WHAT_WE_DO_TITLE,
} from '../../constants'
import StyledContainer from './styles'
import VideoPlayer from '../../_components/VideoPlayer'

const WhatWeDo = () => {
  const sectionRef = useRef(null)
  const isInView = useInView(sectionRef, { amount: .25 })

  const visible = { opacity: 1, y: 0, transition: { duration: 1 } }

  return (
    <StyledContainer>
      <Container
        className='containerWrapper'
        maxWidth='xl'
        ref={sectionRef}
        sx={{
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
        }}
      >
        <motion.div
              variants={{
                hidden: { opacity: 0, y: -100 },
                visible
              }}
              animate={isInView ? 'visible' : 'hidden'}
              >
        <Grid className='heading' container>
          <Grid item lg={6}>
              <h2>{WHAT_WE_DO_TITLE}</h2>
          </Grid>
          <Grid item lg={6}>
            <p>{WHAT_WE_DO_DESCRIPTION}</p>
            <div className='linkWrapper'>
              <Link href='/about'>
                <div>
                  <p>{WHAT_WE_DO_ANCHOR_TEXT}</p>
                  <IconArrowUpRight />
                </div>
              </Link>
            </div>
          </Grid>
        </Grid>

        <div className='videoWrapper'>
            <VideoPlayer src={ABOUT_BG_VIDEO} />
        </div>
        </motion.div>
      </Container>
    </StyledContainer>
  )
}

export default WhatWeDo
