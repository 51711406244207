import { useCallback } from 'react'
import useTextRotation, { UseTextRotationParam } from './useTextRotation'

type UseTwinTextRotationProps = Pick<
  UseTextRotationParam,
  'textItems' | 'duration' | 'direction'
>

/**
 * Applies text rotation to two elements,
 * one of which is the prestige.
 * @param props {@link UseTextRotationParam}
 */
export default function useTwinTextRotation({
  textItems,
  duration,
  direction,
}: UseTwinTextRotationProps) {
  const {
    startAnimation: startTwin1Animation,
    text: twin1Text,
    ref: twin1Ref,
  } = useTextRotation({
    textItems,
    duration,
    direction,
    isTopElement: true,
  })
  const {
    startAnimation: startTwin2Animation,
    text: twin2Text,
    ref: twin2Ref,
  } = useTextRotation({
    textItems,
    duration,
    direction,
    isTopElement: false,
  })

  const startAnimation = useCallback(() => {
    const stopTwin1 = startTwin1Animation()
    const stopTwin2 = startTwin2Animation()
    return function stopAnimation() {
      stopTwin1()
      stopTwin2()
    }
  }, [startTwin1Animation, startTwin2Animation])

  return {
    texts: [twin1Text, twin2Text] as [string, string],
    refs: [twin1Ref, twin2Ref] as [
      React.RefObject<HTMLSpanElement>,
      React.RefObject<HTMLSpanElement>,
    ],
    /**
     * The function used by the hook's consumer to (re)start the animation.
     * @returns A function used to stop the animation
     */
    startAnimation,
  }
}
