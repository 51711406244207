import { styled } from '@mui/material'
import * as webTokens from '@hubble/tokens/web'
import { StyledSectionContainer } from '../../styles'

const StyledContainer = styled(StyledSectionContainer)`
  background-color: ${webTokens.color.base.black};
  color: ${webTokens.color.base.white};
  padding: 0 60px;
  position: relative;

  .trusted-by-wrapper {
    padding: 5% 0px;
    margin: 0px auto;
    max-width: 1200px;
    background-color: ${webTokens.color.base.black};

    .trusted-by {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow: clip;

      .text-container {
        display: flex;
        flex-grow: 1;
        padding-right: 1rem;
        background: ${webTokens.color.base.black};
        .text {
          text-transform: uppercase;
          font-weight: ${webTokens.text.base.weight.semiBold};
          position: relative;
          margin: 0px auto;
          flex-grow: 1;

          font-size: ${webTokens.text.base.scale[96].fontSize};
          line-height: ${webTokens.text.base.scale[96].marketing.lineHeight};
          letter-spacing: ${webTokens.text.base.scale[96].marketing
            .letterSpacing};
          @media (max-width: 1280px) {
            font-size: ${webTokens.text.base.scale[80].fontSize};
            line-height: ${webTokens.text.base.scale[80].marketing.lineHeight};
            letter-spacing: ${webTokens.text.base.scale[80].marketing
              .letterSpacing};
          }
          @media (max-width: 960px) {
            font-size: ${webTokens.text.base.scale[72].fontSize};
            line-height: ${webTokens.text.base.scale[72].marketing.lineHeight};
            letter-spacing: ${webTokens.text.base.scale[72].marketing
              .letterSpacing};
          }
          @media (max-width: 600px) {
            font-size: ${webTokens.text.base.scale[48].fontSize};
            line-height: ${webTokens.text.base.scale[48].marketing.lineHeight};
            letter-spacing: ${webTokens.text.base.scale[48].marketing
              .letterSpacing};
          }
        }
      }

      .image-container {
        background: ${webTokens.color.base.black};
        overflow: clip;
        flex-grow: 1;

        img {
          height: ${webTokens.text.base.scale[96].fontSize};
          width: ${webTokens.text.base.scale[96].fontSize};
        }
        @media (max-width: 1280px) {
          img {
            height: ${webTokens.text.base.scale[80].fontSize};
            width: ${webTokens.text.base.scale[80].fontSize};
          }
        }
        @media (max-width: 960px) {
          img {
            height: ${webTokens.text.base.scale[72].fontSize};
            width: ${webTokens.text.base.scale[72].fontSize};
          }
        }
        @media (max-width: 600px) {
          img {
            height: ${webTokens.text.base.scale[48].fontSize};
            width: ${webTokens.text.base.scale[48].fontSize};
          }
        }
      }

      .image-mask {
        position: relative;
        width: 100%;
        height: 100%;
        background: ${webTokens.color.base.black};
      }
    }
  }
`

export default StyledContainer
