/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { Link, Typography } from '@/elements/src'

import { motion } from 'framer-motion'
import useArtistAnimationBySequence from '../_hooks/useArtistNameAnimation'

interface ArtistNameProps {
  name: string
  href: string
  sequence: Parameters<typeof useArtistAnimationBySequence>[0]
  collectionCount: Parameters<typeof useArtistAnimationBySequence>[1]
}

export default function ArtistName({
  name,
  href,
  sequence,
  collectionCount,
}: ArtistNameProps) {
  const { y, opacity } = useArtistAnimationBySequence(sequence, collectionCount)

  return (
    <motion.div
      className='caseStudyItem--artist-wrapper'
      style={{
        textAlign: 'center',
        position: 'absolute',
        zIndex: 3,
        y,
        opacity,
      }}
    >
      <Link href={href}>
        <Typography
          className='caseStudyItem--artist'
          style={{ cursor: 'pointer' }}
        >
          {name}
        </Typography>
      </Link>
    </motion.div>
  )
}
