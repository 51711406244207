import React, { useEffect, useState } from 'react'
import { CSSProperties } from '@mui/styles'

interface RotatableTextProps {
  style: CSSProperties
  refs: [React.RefObject<HTMLSpanElement>, React.RefObject<HTMLSpanElement>]
  texts: [string, string]
}

/**
 * Contains two stacked span elements that can animate vertically upwards or downwards,
 * each displaying text which is intended to change on each animation cycle,
 * inside of a container sized to make just one element visible at a time,
 * continuously repeating _The Prestige_.
 * @param props.style The style to apply to the twins.
 * @param props.refs The refs for each twin.
 * @param props.texts The display text for each twin.
 */
function RotatableText({ style, texts, refs }: RotatableTextProps) {
  /**
   * Ensue the container is always sized to show just one element.
   * Assumes twins have the same height, though width may differ.
   */
  const [containerHeight, setContainerHeight] = useState(0)
  useEffect(() => {
    if (!refs[0].current) return

    const resizeObserver = new ResizeObserver((observations) => {
      for (const { target } of observations) {
        const { height } = target.getBoundingClientRect()
        if (containerHeight !== height) setContainerHeight(height)
      }
    })

    resizeObserver.observe(refs[0].current)
    return () => resizeObserver.disconnect()
  }, [refs, containerHeight])

  return (
    <div
      style={{
        textAlign: 'center',
        height: containerHeight,
        overflow: 'hidden',
      }}
    >
      <div style={{ lineHeight: 1 }}>
        <span style={style} ref={refs[0]}>
          {texts[0]}
        </span>
      </div>
      <div style={{ lineHeight: 1 }}>
        <span style={style} ref={refs[1]}>
          {texts[1]}
        </span>
      </div>
    </div>
  )
}

export default RotatableText
