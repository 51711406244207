import { motion } from 'framer-motion'
import useArtistAnimationBySequence from '../_hooks/useArtistNameAnimation'

interface DropCollectionTitleProps {
  title: string
  sequence: number
  collectionCount: number
}
export default function DropCollectionTitle({
  title,
  sequence,
  collectionCount,
}: DropCollectionTitleProps) {
  const { opacity } = useArtistAnimationBySequence(sequence, collectionCount)

  return (
    <motion.div
      className='caseStudyItem--collection-name'
      data-collection-sequence={sequence}
      style={{ opacity }}
    >
      {title}
    </motion.div>
  )
}
