import React, { useEffect, useState } from 'react'

import { Container } from '@mui/material'
import { useIntersectionObserver } from '@/elements/src'
import { useAnalytics } from '@/providers/AnalyticsContext'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import HighlightSlide from './HighlightSlide'
// import { CalendarDrop } from '@/types/homepage'
import { ANALYTICS_EVENT_NGS_HOME_UPCOMING_DROPS_VIEWED } from '../../constants'
import MobileDropSlide from './MobileDropSlide'
import DropSlide from './DropSlide'
import StyledContainer from './styles'
import { DropData } from '../../types'

// interface DotProps {
//   drops: CalendarDrop[]
//   currentSlide: number
//   displayed: boolean
//   onClick: (index: number) => {}
// }

// const Dots = ({ drops, currentSlide, displayed, onClick }: DotProps) => {
//   if (!displayed) return null

//   return (
//     <div
//       style={{
//         position: 'absolute',
//         padding: '60px',
//         top: '18%',
//         left: '0%',
//         zIndex: 9999,
//       }}
//     >
//       {drops.map((drop, index) => {
//         return (
//           <div
//             key={`${drop.dropName}-${index}`}
//             style={{
//               height: '24px',
//               width: '24px',
//               cursor: 'pointer',
//               borderRadius: '50%',
//               transition: '0.2s',
//               backgroundColor:
//                 drop.index === currentSlide
//                   ? 'rgba(256, 256, 256, 0.65)'
//                   : 'rgba(256, 256, 256, 0.25)',
//               margin: '10px',
//             }}
//           />
//         )
//       })}
//     </div>
//   )
// }

export interface UpcomingDropsProps {
  autoplay?: boolean
  isHighlight?: boolean
  delay?: number
  drops: DropData[]
}

const UpcomingDrops = ({
  autoplay = false,
  isHighlight = false,
  delay,
  drops,
}: UpcomingDropsProps) => {
  const { logEvent } = useAnalytics()
  const [elementRef] = useIntersectionObserver({
    fireOnce: true,
    cb: () =>
      logEvent?.(ANALYTICS_EVENT_NGS_HOME_UPCOMING_DROPS_VIEWED, 'Location'),
    opts: {
      threshold: 0,
    },
  })

  const [activeIndex, setActiveIndex] = useState(0)
  const matches750 = useMediaQueryState('(max-width:750px)')
  const matches1280 = useMediaQueryState('(min-width:1280px)')

  const next = () =>
    setActiveIndex(activeIndex < drops.length - 1 ? activeIndex + 1 : 0)

  useEffect(() => {
    if (autoplay) {
      setTimeout(() => {
        next()
      }, delay || 8000)
    }
  }, [activeIndex, next])

  if (isHighlight) {
    return (
      <StyledContainer ref={elementRef as React.RefObject<HTMLDivElement>}>
        <Container
          className='wrapper'
          maxWidth='xl'
          sx={{
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          }}
        >
          {drops.map((drop, i) => (
            <HighlightSlide drop={drop} key={`highlight-slide-${i}`} />
          ))}
        </Container>
      </StyledContainer>
    )
  }

  if (matches750) {
    return (
      <StyledContainer ref={elementRef as React.RefObject<HTMLDivElement>}>
        <Container
          className='wrapper'
          maxWidth='xl'
          sx={{
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          }}
        >
          {drops.map((drop) => (
            <MobileDropSlide key={drop.dropName} drop={drop} />
          ))}
        </Container>
      </StyledContainer>
    )
  }

  return (
    <StyledContainer ref={elementRef as React.RefObject<HTMLDivElement>}>
      <Container
        className='wrapper'
        maxWidth='xl'
        sx={{
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
        }}
      >
        {drops.map((drop) => (
          <DropSlide
            key={drop.dropName}
            isActive
            drop={drop}
            onClick={async (index) => setActiveIndex(index)}
          />
        ))}
      </Container>
    </StyledContainer>
  )
}

export default UpcomingDrops
