import { styled } from '@mui/material'
import * as webTokens from '@hubble/tokens/web'
import { StyledSectionContainer } from '../../styles'

const StyledContainer = styled(StyledSectionContainer)`
  background-color: #000;
  color: ${webTokens.color.base.white};
  padding: 0 60px;
  position: relative;

  @media (max-width: 750px) {
    padding: 0 20px;
  }

  .containerWrapper {
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .videoWrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    video {
      width: 100%;
    }
  }

  .linkWrapper {
    margin-top: 28px;

    a {
      & > div:first-of-type {
        display: flex;
        align-items: center;
        color: #fff;
      }
    }
  }

  .heading {
    position: relative;
    text-align: left;
    margin-bottom: 10%;

    h2 {
      margin-top: 0;
      font-weight: ${webTokens.text.base.weight.semiBold};
      font-size: ${webTokens.text.base.scale[72].fontSize};
      line-height: ${webTokens.text.base.scale[72].marketing.lineHeight};
      letter-spacing: ${webTokens.text.base.scale[72].marketing.letterSpacing};
    }
  }

  p {
    margin: 0;
    font-weight: ${webTokens.text.base.weight.regular};
    font-size: ${webTokens.text.base.scale[24].fontSize};
    line-height: ${webTokens.text.base.scale[24].marketing.lineHeight};
    letter-spacing: ${webTokens.text.base.scale[24].marketing.letterSpacing};
  }

  @media (max-width: 800px) {
    .heading {
      h2 {
        font-size: ${webTokens.text.base.scale[56].fontSize};
        line-height: ${webTokens.text.base.scale[56].marketing.lineHeight};
        letter-spacing: ${webTokens.text.base.scale[56].marketing
          .letterSpacing};
      }
    }

    p {
      font-size: ${webTokens.text.base.scale[16].fontSize};
      line-height: ${webTokens.text.base.scale[16].marketing.lineHeight};
      letter-spacing: ${webTokens.text.base.scale[16].marketing.letterSpacing};
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 734px) and (max-height: 765px),
    only screen and (min-width: 735px) and (max-width: 1068px) and (max-height: 663px),
    only screen and (min-width: 1069px) and (max-width: 1440px) and (max-height: 709px),
    only screen and (min-width: 1441px) and (max-height: 770px) {
    height: auto !important;

    .heading {
      margin-bottom: 0;
    }
  }
`

export default StyledContainer
