import React, { HTMLAttributes, useRef } from 'react'
import { motion, useInView } from 'framer-motion'

interface ScaleOnEnterProps {
  /**
   * The class to apply to the container element
   */
  className?: HTMLAttributes<HTMLDivElement>['className']
}

/**
 * Scales a container from 0x to 1x their normal size
 * as the children
 * @param props {@link ScaleOnEnterProps}
 * @returns
 */
export default function ScaleOnEnter({
  className,
  children,
}: React.PropsWithChildren<ScaleOnEnterProps>) {

  const ref = useRef<HTMLDivElement>(null)
  const isInView = useInView(ref, {
    margin: '0px 0px -5% 0px',
    once: true,
  })

  return (
    <div ref={ref}>
      <motion.div
        className={className}
        animate={{
          scale: isInView ? 1 : 0,
        }}
        style={{
          transformOrigin: '0% 100%', // scale up from the bottom left
        }}
        transition={{
          duration: 0.5,
        }}
      >
        {children}
      </motion.div>
    </div>
  )
}
